<template>
    <section aria-labelledby="announcements-title" class="relative min-h-[200px] shadow rounded-lg bg-white dark:bg-gray-750">
        <template v-if="loading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>
        <div class="rounded-lg overflow-hidden">
            <div class="p-5">
                <div class="flex items-center justify-between">
                    <h2 class="font-semibold text-2xl dark:text-gray-300">{{component.display_name}}</h2>
                </div>

                <div class="flow-root mt-6 bg-white dark:bg-gray-750 rounded-lg p-3">
                    <ul role="list" class="-my-5">
                        <template v-if="categories && chartData.datasets.length && chartData.datasets[0].data ">

                            <div class="flex items-center justify-center">
                                <button
                                    class="px-3 py-1 border border-gray-400 rounded mx-2 mb-3 text-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                    :class="[!thisYear ? 'bg-gray-300 dark:bg-gray-900' : '']"
                                    @click="thisYear = false"
                                >Šomēnes</button>

                                <button
                                    class="px-3 py-1 border border-gray-400 rounded mx-2 mb-3 text-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                    :class="[thisYear ? 'bg-gray-300 dark:bg-gray-900' : '']"
                                    @click="thisYear = true"
                                >Šogad</button>
                            </div>
                            <template v-if="chartData.datasets[0].data.length > 0">
                                <pie-chart :chartData="chartData" :chartOptions="chartOptions"></pie-chart>
                            </template>

                            <template v-else>
                                <div class="mx-auto">
                                    <img class="max-h-64 mx-auto" src="/no_data.png">
                                </div>
                            </template>
                        </template>

                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex"
var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;
import { defineAsyncComponent } from 'vue'

const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))
import PieChart from "./PieChart.vue"

export default {
    name: "CategoriesWidget",
    components: {
        Loading,
        PieChart,
    },
    props: {
        component: {
            type: Object
        }
    },
    computed: {
        ...mapGetters({
            darkModeStatus: 'darkModeStatus'
        })
    },
    watch: {
        darkModeStatus() {
            this.getData()
            this.setChartLabelColor()
        },
        thisYear() {
            this.getData()
        }
    },
    data() {
        return {
            categories: null,
            loading: false,
            thisYear: false,

            chartData: {
                datasets: [{
                    borderWidth: 1,
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)'
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(255, 206, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                    ],
                }]
            },
            chartOptions: {
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: true,
                        labels: {
                            color: this.darkModeStatus ? "white" : "black",
                        }
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        }
    },
    methods: {
        getData() {
            this.loading = true
            const thisYear = this.thisYear ? "?this_year=true" : ""
            axios
                .get(baseUrl + "/api/dashboard_components/" + this.component.id + thisYear)
                .then((response) => {
                    this.categories = response.data
                    this.chartData.labels = response.data.map(item => item.name)
                    this.chartData.datasets[0].data = response.data.map(item => item.order_item_sum)
                    
                    this.loading = false
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.dispatch("destroyToken");
                    }
                    this.loading = false
                });
        },
        setChartLabelColor() {
            if(this.darkModeStatus) {
                this.chartOptions.plugins.legend.labels.color = "white"
            } else {
                this.chartOptions.plugins.legend.labels.color = "black"
            }
        }
    },
    created() {
        this.getData()
        this.setChartLabelColor()
    }
}
</script>

<style>

</style>