<template>
    <Pie :chart-data="chartData" :chart-options="chartOptions"></Pie>
</template>

<script>
  import { Pie } from 'vue-chartjs'
  import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  export default {
    name: "PieChart",
    components: {
        Pie
    },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        chartOptions: {
            type: Object,
            default: () => {}
        }
    },
  }
</script>